const initState = {
  drawingTools: [
    "Length",
    "Probe",
    "Line",
    "EllipticalRoi",
    "CircleRoi",
    "Brush",
    "RectangleRoi",
    "Text",
    "Angle",
    "RectangleScissors",
    "FreehandRoi",
    "Lison",
    "LisonProbe",
    "MultiLine",
    "MultiPointProbe",
  ],
  dicom_data: {},
  dicom_data_rest_annotation: {},
  currentFrame: 0,
  update: 0,
  width: 80,
  changeId: null,
  changeFrameType: "",
  selectedTool: "MOUSE",
  selectedKey: null,
  syncScans: false,
  thicknessLUT: {},
  update_data: {},
  stacks: [],
  lineSettings: {
    lineNames: [
      "ILM",
      "RNFL",
      "GCL",
      "IPL",
      "INL",
      "OPL",
      "ONL",
      "ELM",
      "PR",
      "RPE",
      "BM",
    ],
    showHandles: true,
  },
  canSyncScans: false,
  LisonActive: false,
  LisonData: [],
  MlLisonsInputData: [],
  MlLisonsInputsSubmitStatus: false,
  SubmitLisonsStatus: false,
  errorMessage: " ",
  lisonOptions: {
    lisonAreaselected: "modified_spline",
    lisonAreaOptions: [],
    lisonDisplaySelected: "filled",
    lisonDisplayOptions: ["Edges", "filled"],
  },
  lisonsAreasData: {},
  OpRef: null,
  basicMetaData: {},
  SelectedArea: {
    status: false,
    TotalArea: null,
    LisonsArea: "",
  },
  formDataUserGrading: {
    data: {},
  },
  mainScreenShotUrl: null,
  displayScreenShotUrl: "",
  settings: {
    brush: {
      showMasks: false,
      borderRadius: 15,
      activeSegment: 1,
      showEtdrsMirroring: false,
    },
    screenshot: {
      irBscan: false,
      onlyIr: true,
      onlyBscan: false,
    },
  },
  LoadingOverlay: false,
  fetchedId: null,
  screenShotDisplay: false,
  mlLesionPlayloadData: null,
  mlLesionResponseData: null,
  adjudication_id: "",

  optMetaData: null,
  submitDisable: false,
  dicomRefCombined: null,
  dicomRefBscan: null,
  etdrsActive: false,
  etdrsFallBack: false,
  takeScreenShot: null,
  prevStartRef: null,
  isEtdrsActive: false,
  makeEtdrsActive: true,
};
const dicomReducer = (state = initState, action) => {
  switch (action.type) {
    case "makeEtdrsActive_changed":
      return {
        ...state,
        makeEtdrsActive: action.makeEtdrsActive,
      };
    case "takeScreenShot_changed":
      return {
        ...state,
        takeScreenShot: action.takeScreenShot,
      };
    case "isEtdrsActive_changed":
      return {
        ...state,
        isEtdrsActive: action.isEtdrsActive,
      };
    case "etdrsFallBack_changed":
      return {
        ...state,
        etdrsFallBack: action.etdrsFallBack,
      };

    case "etdrs_changed":
      return {
        ...state,
        etdrsActive: action.etdrsActive,
      };
    case "dicomRef_changed":
      return {
        ...state,
        dicomRefCombined: action.dicomRef,
      };

    // case "dicomRefIRscan_changed":
    //   return {
    //     ...state,
    //     dicomRefIRscan: action.dicomRef,
    //   };

    case "dicomRefBscan_changed":
      return {
        ...state,
        dicomRefBscan: action.dicomRef,
      };
    case "submitDisabled_changed":
      return {
        ...state,
        submitDisable: action.submitDisable,
      };

    case "optMetaData_changed":
      return {
        ...state,
        optMetaData: action.optMetaData,
      };

    case "Adjudication_id_changed":
      return {
        ...state,
        adjudication_id: action.adjudication_id,
      };

    case "mlLesion_Data_changed":
      return {
        ...state,
        mlLesionPlayloadData: action.mlLesionPlayloadData,
        mlLesionResponseData: action.mlLesionResponseData,
      };

    case "screenShot_Display_changed":
      return {
        ...state,
        screenShotDisplay: action.screenShotDisplay,
      };

    case "displayScreenShot_url_changed":
      return {
        ...state,
        displayScreenShotUrl: action.displayScreenShotUrl,
      };
    case "mainScreenShot_url_changed":
      return {
        ...state,
        mainScreenShotUrl: action.mainScreenShotUrl,
      };

    case "fetchedId_changed":
      return {
        ...state,
        fetchedId: action.fetchedId,
      };
    case "roledropdowndata_changed":
      return {
        ...state,
        formDataUserGrading: action.formDataUserGrading,
      };

    case "LoadingOverlay_changed":
      return {
        ...state,
        LoadingOverlay: action.LoadingOverlay,
      };
    case "Lison_Submit_Status_changed":
      return {
        ...state,
        SubmitLisonsStatus: action.SubmitLisonsStatus,
      };
    case "Set_BasicMetaData":
      console.log("action.basicMetaData", action?.basicMetaData);
      return {
        ...state,
        basicMetaData: action.basicMetaData,
      };
    case "selected_area_Changed":
      return {
        ...state,
        SelectedArea: action.SelectedArea,
      };
    case "Lison_Input_Submit_Status_changed":
      return {
        ...state,
        MlLisonsInputsSubmitStatus: action.MlLisonsInputsSubmitStatus,
      };
    case "Op_Ref_Changed":
      return {
        ...state,
        OpRef: action.OpRef,
      };

    case "Lison_Input_changed":
      return {
        ...state,
        MlLisonsInputData: action.MlLisonsInputData,
      };

    case "Lison_Option_changed":
      return {
        ...state,
        lisonOptions: action.lisonOptions,
      };
    case "Set_Lisons_Area_Data":
      return {
        ...state,
        lisonsAreasData: action.lisonsAreasData,
      };

    case "Lison_toggle_DATA":
      return {
        ...state,
        LisonData: action.LisonData,
      };

    case "Annotation_Rest_DATA":
      return {
        ...state,
        dicom_data_rest_annotation: action.rest_annotations_data,
      };
    case "Change_Lison_Active":
      return {
        ...state,
        LisonActive: action.lisonStatus,
      };
    case "NO_OF_STACKS":
      return {
        ...state,
        stacks: action.stacks,
      };
    case "DICOM_FETCH_META_SUCCESS":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.id]: action.dicom_data,
        },
      };

    case "REMOVE_STACK":
      let new_dicom_data = { ...state.dicom_data };
      delete new_dicom_data[action.id];
      return {
        ...state,
        dicom_data: new_dicom_data,
      };

    case "DICOM_CAN_SYNC_SCANS": {
      return {
        ...state,
        canSyncScans: action.canSyncScans,
      };
    }

    case "DICOM_SYNC_SCANS": {
      return {
        ...state,
        syncScans: action.syncScans,
      };
    }

    case "DICOM_CHANGE_FRAME":
      return {
        ...state,
        currentFrame: action.frame,
        changeFrameType: action.changeType,
        changeId: action.changeId,
      };

    case "SET_OPT_STACK":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.id]: {
            ...state.dicom_data[action.id],
            OPT: {
              ...state.dicom_data[action.id].OPT,
              stack: action.payload,
            },
            OP: {
              ...state.dicom_data[action.id].OP,
              vertical_line: null,
            },
          },
        },
      };

    case "SET_ANNOTATION_DATA":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.id]: {
            ...state.dicom_data[action.id],
            [action.key]: {
              ...state.dicom_data[action.id][action.key],
              annotations: action.payload,
            },
          },
        },
      };

    // On vertical line change (either from OP or other OPT)
    case "DICOM_CHANGE_VERTICAL_LINE":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.id]: {
            ...state.dicom_data[action.id],
            OPT: {
              ...state.dicom_data[action.id].OPT,
              vertical_line: action.OPT,
            },
            OP: {
              ...state.dicom_data[action.id].OP,
              vertical_line: action.OP,
            },
          },
        },
      };

    case "ANNOTATION_CHANGE_REQUEST":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.stack_id]: {
            ...state.dicom_data[action.stack_id],
            [action.key]: {
              ...state.dicom_data[action.stack_id][action.key],
              annotation_toggle: action.payload,
            },
          },
        },
      };

    case "TOGGLE_ANNOTATION_VISIBILITY":
      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.stack_id]: {
            ...state.dicom_data[action.stack_id],
            [action.key]: {
              ...state.dicom_data[action.stack_id][action.key],
              update: true,
              annotations: {
                ...state.dicom_data[action.stack_id][action.key].annotations,
                [action.payload.id]: {
                  ...state.dicom_data[action.stack_id][action.key].annotations[
                    action.payload.id
                  ],
                  data: state.dicom_data[action.stack_id][
                    action.key
                  ].annotations[action.payload.id].data.map(
                    (annot, annot_index) => {
                      if (annot_index === action.payload.index) {
                        return {
                          ...annot,
                          visible: action.payload.visible,
                        };
                      }
                    }
                  ),
                },
              },
            },
          },
        },
      };
    case "SET_THICKNESS_MAP":
      // Add thickness map to OP for stack id

      return {
        ...state,
        dicom_data: {
          ...state.dicom_data,
          [action.id]: {
            ...state.dicom_data[action.id],
            OP: {
              ...state.dicom_data[action.id].OP,
              thickness_map: action.payload,
            },
          },
        },
      };

    case "CHANGE_SELECTED_TOOL":
      return {
        ...state,
        selectedTool: action.payload,
      };
    case "SET_SELECTED_KEY":
      return {
        ...state,
        selectedKey: action.key,
      };

    case "SET_GRID_WIDTH":
      return {
        ...state,
        width: action.width,
      };

    case "SET_THICKNESS_LUT":
      return {
        ...state,
        thicknessLUT: action.lut,
      };

    // Change brush settings
    case "SET_BRUSH_SEGMENTATION_INDEX":
      return {
        ...state,
        settings: {
          ...state.settings,
          brush: {
            ...state.settings.brush,
            activeSegment: action.index,
          },
        },
      };
    case "SET_BRUSH_SHOW_MASKS":
      return {
        ...state,
        settings: {
          ...state.settings,
          brush: {
            ...state.settings.brush,
            showMasks: action.showMasks,
          },
        },
      };
    case "SET_BRUSH_SHOW_ETDRS_MIRRORING":
      return {
        ...state,
        settings: {
          ...state.settings,
          brush: {
            ...state.settings.brush,
            showEtdrsMirroring: action.showEtdrsMirroring,
          },
        },
      };
    case "SET_BRUSH_BORDER_RADIUS":
      return {
        ...state,
        settings: {
          ...state.settings,
          brush: {
            ...state.settings.brush,
            borderRadius: action.borderRadius,
          },
        },
      };
    case "SET_SCREENSHOT_OPTION":
      return {
        ...state,
        settings: {
          ...state.settings,
          screenshot: {
            ...action.payload,
          },
        },
      };

    case "UPDATE_IMAGE":
      return {
        ...state,
        update: state.update + 1,
      };

    case "UPDATE_DATA":
      return {
        ...state,
        update_data: action.data,
      };

    case "SET_PREV_START_REF":
      return {
        ...state,
        prevStartRef: action.payload,
      };

    default:
      return state;
  }
};
export default dicomReducer;
