import React, { useState, useEffect, useRef } from "react";
import { fetch_dicom_metdata } from "./actions/dcm_actions";

import Splitter from "@devbookhq/splitter";
import { useDispatch, useSelector } from "react-redux";
import OPViewer from "./Viewers/OPViewer";
import OPTViewer from "./Viewers/OPTViewer";
import ViewerNav from "./ViewerNav";
import "./styles.css";

function LoadingComponent(props) {
  const [gridSize, setGridSize] = useState([70, 30]);

  const handleResizeFinished = (ids, newSizes) => {
    //onWindowResize()
    setGridSize(newSizes);
  };
  return (
    <div
      style={{ height: props.height, display: "flex", position: "relative" }}
    >
      {props.showClose === "yes" ? (
        <div
          onClick={() => props.removeStackLoader()}
          style={{
            backgroundColor: "#65350f",
            cursor: "pointer",
            color: "white",
            height: "20px",
            padding: 0,
            fontSize: 24,
            margin: 0,
            width: 28,
            textAlign: "center",
            position: "absolute",
            top: 0,
            right: 1,
            lineHeight: 0.6,
          }}
        >
          &times;
        </div>
      ) : null}
      <Splitter
        style={{ height: props.height }}
        onResizeFinished={handleResizeFinished}
        gutterClassName="custom-gutter-horizontal"
        initialSizes={gridSize}
      >
        <div style={{ height: "100%" }}>
          <ViewerNav metadata={{}} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              textAlign: "center",
            }}
          >
            {props.message}
          </div>
        </div>

        <div style={{ height: "100%" }}>
          <ViewerNav metadata={{}} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              textAlign: "center",
            }}
          >
            {props.message}
          </div>
        </div>
      </Splitter>
    </div>
  );
}

export default function SingleDICOMViewer(props) {
  const scheme = "wadouri";
  const dcm_id = props.dicom_id;
  const dicomRef = useRef(null);
  const opViewerRefwithMetadata = useRef(null);

  const dispatch = useDispatch();
  const dicom_data = useSelector((state) => state.dcm);

  useEffect(() => {
    dispatch({
      type: "DICOM_FETCH_META_SUCCESS",
      dicom_data: {
        OP: null,
        OPT: null,
        loading: true,
      },
      id: dcm_id,
    });

    fetch_dicom_metdata(
      props.urls,
      scheme,
      dcm_id,
      dispatch,
      dicom_data.dicom_data
    );
  }, []);

  useEffect(() => {
    let rest_annotations = {};

    if (rest_annotations["OP"] && rest_annotations["OP"]) {
      dicom_data.stacks?.forEach((key) => {
        rest_annotations[key] = { ...props?.stack?.annotations };
      });
    } else {
      rest_annotations = { ...props?.stack?.annotations };
      delete rest_annotations[props.series_no];
    }

    if (rest_annotations && dicom_data.stacks?.length > 1) {
      dispatch({
        type: "Annotation_Rest_DATA",
        rest_annotations_data: rest_annotations,
      });
    }
  }, [props.stack, dicom_data.stacks]);

  useEffect(() => {
    if (dicom_data?.OpRef?.current) {
      console.log("AOA Op_Ref_Changed AOA");
      dispatch({
        type: "dicomRef_changed",
        dicomRef: dicomRef,
      });
    }
  }, [dicom_data.OpRef]);
  useEffect(() => {
    var imageUrl = "/colormap.png";
    var colormap = new Image();
    colormap.src = imageUrl;
    colormap.onload = function () {
      // iterate over the colormap and create a lookup table
      var canvas = document.createElement("canvas");
      canvas.width = colormap.width;
      canvas.height = colormap.height;
      var context = canvas.getContext("2d");
      context.drawImage(colormap, 0, 0);
      var colormapData = context.getImageData(
        0,
        0,
        colormap.width,
        colormap.height
      ).data;

      // Create RGB LUT
      var total = colormap.width * colormap.height;
      var rgbLut = {};
      for (var i = 0; i < colormapData.length; i += 4) {
        var r = colormapData[i];
        var g = colormapData[i + 1];
        var b = colormapData[i + 2];
        rgbLut[total] = [r, g, b];
        total -= 1;
      }
      dispatch({
        type: "SET_THICKNESS_LUT",
        lut: rgbLut,
      });
    };
  }, []);

  const [gridSize, setGridSize] = useState([40, 60]);
  useEffect(() => {
    if (opViewerRefwithMetadata) {
      console.log("opViewerRefwithMetadata", opViewerRefwithMetadata);
      dispatch({
        type: "Op_Ref_Changed",
        OpRef: opViewerRefwithMetadata,
      });
    }
  }, [opViewerRefwithMetadata]);
  const handleResizeFinished = (ids, newSizes) => {
    //onWindowResize()
    setGridSize(newSizes);
  };

  if (dicom_data.dicom_data[dcm_id] === undefined) {
    return <LoadingComponent message="Loading..." height={props.height} />;
  }

  if (
    dicom_data.dicom_data[dcm_id].OP === null ||
    dicom_data.dicom_data[dcm_id].OPT === null
  ) {
    return <LoadingComponent message="Loading..." height={props.height} />;
  }
  /*
  if (dicom_data.dicom_data[dcm_id].isValidDcm == false) {
    return (
      <LoadingComponent removeStackLoader={removeStackLoader} showClose="yes" message="Not a FollowUp Scan" height={props.height} />
    )
  }
  */
  console.log("dicom_data.dicom_data[dcm_id]", dicom_data.dicom_data[dcm_id]);

  return (
    <div
      style={{ height: props.height, display: "flex", position: "relative" }}
      ref={dicomRef}
    >
      {props.index !== 0 ? (
        <div
          onClick={() => props.removeStack(dcm_id)}
          style={{
            backgroundColor: "#65350f",
            cursor: "pointer",
            color: "white",
            height: "20px",
            padding: 0,
            fontSize: 24,
            margin: 0,
            width: 28,
            textAlign: "center",
            position: "absolute",
            top: 0,
            right: 1,
            lineHeight: 0.6,
          }}
        >
          &times;
        </div>
      ) : null}
      <Splitter
        style={{ height: props.height }}
        onResizeFinished={handleResizeFinished}
        gutterClassName="custom-gutter-horizontal"
        initialSizes={gridSize}
      >
        <div style={{ height: "100%" }} ref={opViewerRefwithMetadata}>
          <ViewerNav
            metadata={dicom_data.dicom_data[dcm_id].OP.metadata}
            text={
              dicom_data.dicom_data[dcm_id].isValidDcm
                ? undefined
                : "Not a FollowUp Scan"
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 30px)",
              backgroundColor: "black",
            }}
          >
            <div style={{ height: "calc(100% - 95px)" }}>
              <OPViewer
                annotations={props.annotations.OP}
                id={dcm_id}
                width={"100%"}
                height={"100%"}
                windowWidth={gridSize[0]}
                thickness_map={props.thickness_map}
              />
            </div>
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "5px",
                textAlign: "left",
                whiteSpace: "pre-line",
                marginBottom: "50px",
              }}
            >
              {`${
                dicom_data.dicom_data[
                  dcm_id
                ]?.OP?.metadata?.acquisition_date?.split(" ")[0]
              } - ${props?.metadata?.visit_label}`}

              <br />
              {`${props?.metadata?.subject_code} - ${props?.metadata?.study_code} `}
            </div>
          </div>
        </div>

        <div style={{ height: "100%" }}>
          <ViewerNav
            metadata={dicom_data.dicom_data[dcm_id].OP.metadata}
            text={
              dicom_data.dicom_data[dcm_id].isValidDcm
                ? undefined
                : "Not a FollowUp Scan"
            }
          />
          <OPTViewer
            masks={props.masks}
            annotations={props.annotations.OPT}
            id={dcm_id}
            width={"100%"}
            height={"100%"}
            windowWidth={gridSize[1]}
          />
        </div>
      </Splitter>
    </div>
  );
}
