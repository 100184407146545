import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Button from "@mui/material/Button";
import { useParams, useLocation } from "react-router";
import SingleDICOMViewer from "./SingleDICOMViewer";
import { API_URL } from "../../Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import cornerstone from "cornerstone-core";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { styled } from "@mui/system";
import ToolButtons from "./ToolButtons";

// Modified Tools
import ProbeTool from "./tools/annotation/Probe";
import RectangleRoiTool from "./tools/annotation/RectangleRoiTool";
import LengthTool from "./tools/annotation/LengthTool";
import EllipticalRoiTool from "./tools/annotation/EllipticalRoiTool";
import LineTool from "./tools/annotation/LineTool";

// Custom Tools
import ThicknessMap from "./tools/ThicknessMap";

// Static Tools (Just for visualizing)
import LineStatic from "./tools/LineStatic";
import RectangleStatic from "./tools/RectangleStatic";
import TextStatic from "./tools/TextStatic";
import CircleStatic from "./tools/CircleStatic";

import CloudSyncIcon from "@mui/icons-material/CloudSync";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";

import Splitter from "@devbookhq/splitter";
import AnnotationsData from "./Annotations/AnnotationsData";

import SyncIcon from "@mui/icons-material/Sync";
import AddStackDialog from "../../Components/AddStackDialog";
import HelpDialog from "./Dialogs/HelpDialog";

import { save_annotations } from "./actions/dcm_actions";
import LoaderScreen from "../../Components/LoaderScreen";
import MetaDataDialog from "./Dialogs/DicomMetaData";
import NavbarWithMeta from "../../Components/NavbarWithMeta";
import LisonTool from "./tools/annotation/LisonTool";
import LisonProbe from "./tools/annotation/LisonProbe";
import Etdrs from "./tools/Etdrs";

cornerstoneTools.external.cornerstone = cornerstone;

// Dicom tools
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;

cornerstoneTools.external.Hammer = Hammer;

// Modified Tools
cornerstoneTools.LisonProbeTool = LisonProbe;
cornerstoneTools.ProbeTool = ProbeTool;
cornerstoneTools.RectangleRoiTool = RectangleRoiTool;
cornerstoneTools.LengthTool = LengthTool;
cornerstoneTools.LisonTool = LisonTool;
cornerstoneTools.EllipticalRoiTool = EllipticalRoiTool;
cornerstoneTools.LineTool = LineTool;

// Custom Tools
cornerstoneTools.ThicknessMap = ThicknessMap;
cornerstoneTools.Etdrs = Etdrs;

// Static tools (Just for drawing)
cornerstoneTools.LineStatic = LineStatic;
cornerstoneTools.RectangleStatic = RectangleStatic;
cornerstoneTools.TextStatic = TextStatic;
cornerstoneTools.CircleStatic = CircleStatic;

cornerstoneWADOImageLoader.configure({
  beforeSend: function (xhr) {
    // Cors
    //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );

    // Add custom headers here (e.g. auth tokens)
    //xhr.setRequestHeader('x-auth-token', 'my auth token');
  },
  useWebWorkers: true,
});

cornerstoneWebImageLoader.configure({
  beforeSend: function (xhr) {
    // Cors
    //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
  },
  useWebWorkers: true,
});

cornerstoneTools.init({ mouseEnabled: true });

const LayoutContainer = styled("div")(() => ({
  height: "calc(100vh - 57px)",
  overflow: "hidden",
  width: "100%",
  backgroundColor: "rgb(220, 220, 220)",
}));

const theme = createTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
    default: {
      main: "#999",
    },
  },
});

export default function DCMViewer(props) {
  const dispatch = useDispatch();
  const dicom_data = useSelector((state) => state.dcm);

  const match = useParams();
  const id = match.id;
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const opData = dicom_data.dicom_data[query.get("stack_id")];
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [addStackDialogOpen, setAddStackDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const keysDis = ["Shift", "Alt", "a", "A"];

  const onKeyboardKeyDown = (e) => {
    if (
      e.key === "ArrowUp" &&
      dicom_data.currentFrame < opData.OPT.metadata.frames.length - 1
    ) {
      dispatch({
        type: "DICOM_CHANGE_FRAME",
        changeId: query.get("stack_id"),
        changeType: "OP",
        frame: dicom_data.currentFrame + 1,
      });
    }

    if (e.key === "ArrowDown" && dicom_data.currentFrame !== 0) {
      dispatch({
        type: "DICOM_CHANGE_FRAME",
        changeId: query.get("stack_id"),
        changeType: "OP",
        frame: dicom_data.currentFrame - 1,
      });
    }

    if (keysDis.includes(e.key)) {
      cornerstoneTools.setToolPassive(dicom_data.selectedTool);
      cornerstoneTools.setToolDisabled("stack");
      cornerstoneTools.setToolDisabled("StackScrollMouseWheel");
    } else if (["z", "Z"].includes(e.key)) {
      cornerstoneTools.setToolDisabled("StackScrollMouseWheel");
    }
    dispatch({
      type: "SET_SELECTED_KEY",
      key: e.key,
    });
  };

  const onKeyboardKeyUp = (e) => {
    if (keysDis.includes(e.key)) {
      cornerstoneTools.setToolActive("stack");
      cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
      cornerstoneTools.setToolActive(dicom_data.selectedTool, {
        mouseButtonMask: 1,
      });
    } else if (["z", "Z"].includes(e.key)) {
      cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
    }
    dispatch({
      type: "SET_SELECTED_KEY",
      key: null,
    });
  };
  useEffect(() => {
    document.addEventListener("keydown", onKeyboardKeyDown);
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", onKeyboardKeyDown);
    document.addEventListener("keyup", onKeyboardKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyboardKeyDown);
      document.removeEventListener("keyup", onKeyboardKeyUp);
    };
  }, [dicom_data.selectedTool, dicom_data.currentFrame]);

  const [dicomStacks, setDicomStacks] = useState([]);

  const fetchOptMetaData = async () => {
    if (
      dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url &&
      !dicom_data.optMetaData
    ) {
      const optUrl = dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url;

      const response = await axios.post(`${API_URL}/api/v1/dicom/meta`, {
        url: optUrl,
      });
      console.log("dicom_data?.fetchOptMetaData", response.data);

      dispatch({
        type: "optMetaData_changed",
        optMetaData: response.data,
      });
    }
  };

  const fetchStacks = async () => {
    const response = await axios.get(
      `${API_URL}/api/v1/stacks?id=${query.get("stack_id")}&user_id=${query.get(
        "user_id"
      )}&series=${query.get("series_no")}`
    );
  };

  useEffect(() => {
    console.log("dicomStacks", dicomStacks);
    if (dicomStacks[0]?.basic_metadata) {
      dispatch({
        type: "Set_BasicMetaData",
        basicMetaData: dicomStacks[0].basic_metadata,
      });
    }
  }, [dicomStacks]);
  useEffect(() => {
    const fetchDicomStacks = async () => {
      const form_version_id = query.get("form_version_id")
        ? query.get("form_version_id")
        : "";
      const form_type = query.get("form_version_id")
        ? "additional_form"
        : "primary_form";

      const response = await axios.get(
        `${API_URL}/api/v1/file?id=${query.get("stack_id")}&user_id=${
          localStorage.getItem(query.get("stack_id")) &&
          query.get("role") === "adjudicator"
            ? localStorage.getItem(query.get("stack_id"))
            : query.get("user_id")
        }&series=${query.get(
          "series_no"
        )}&form_version_id=${form_version_id}&form_type=${form_type}`
      );

      if (query.get("role") === "adjudicator") {
        dispatch({
          type: "fetchedId_changed",
          fetchedId:
            localStorage.getItem(query.get("stack_id")) ?? query.get("user_id"),
        });
      }
      ///
      console.log("beforee iffff", {
        role: query.get("role"),
        localStackId: localStorage.getItem(query.get("stack_id")),
      });

      if (
        (query.get("role") === "adjudicator" &&
          !localStorage.getItem(query.get("stack_id"))) ||
        (query.get("role") === "adjudicator" &&
          localStorage.getItem(query.get("stack_id")) === query.get("user_id"))
      ) {
        axios
          .get(
            `${API_URL}/api/v1/qa-form-adjudicator?stack_id=${query.get(
              "stack_id"
            )}&user_id=${query.get("user_id")}${
              query.get("form_version_id")
                ? `&form_version_id=${query.get("form_version_id")}`
                : ""
            }`
          )
          .then((res) => {
            console.log("theeen ", res);
            if (res.data.annotations) {
              response.data.annotations = res.data.annotations;
            }
          })
          .catch((err) => {
            console.log("theeen else");
          });
      }

      ////

      if (response.data.status === "failed") {
        setApiError(true);
      } else {
        setDicomStacks([response.data]);
      }
      setIsLoading(false);
    };
    fetchDicomStacks();
    fetchStacks();
  }, []);

  useEffect(() => {
    fetchOptMetaData();
  }, [dicom_data?.dicom_data]);

  const addStack = (files) => {
    setDicomStacks([...dicomStacks, files]);
    setAddStackDialogOpen(false);
  };

  const removeStack = (dcm_id) => {
    dispatch({
      type: "REMOVE_STACK",
      id: dcm_id,
    });

    setDicomStacks(dicomStacks.filter((stack) => stack.id !== dcm_id));
  };

  const onSaveClick = async () => {
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    setIsSaving(true);
    await save_annotations(
      dicom_data,
      query.get("stack_id"),
      query.get("user_id"),
      query.get("series_no"),
      query.get("role"),
      query.get("form_version_id") ? "additional_form" : "primary_form",
      query.get("form_version_id") ? query.get("form_version_id") : ""
    );
    setIsSaving(false);

    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  const [gridSize, setGridSize] = useState([85, 15]);
  const handleResizeFinished = (ids, newSizes) => {
    //onWindowResize()
    setGridSize(newSizes);
    dispatch({
      type: "SET_GRID_WIDTH",
      width: newSizes[0],
    });
  };

  if (isLoading) {
    return (
      <div>
        <Navbar />
        <LayoutContainer>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <LoaderScreen />
          </div>
        </LayoutContainer>
      </div>
    );
  }

  if (apiError) {
    return (
      <div>
        <Navbar />
        <LayoutContainer>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <h2 style={{ color: "brown" }}>Failed to fetch stack</h2>
          </div>
        </LayoutContainer>
      </div>
    );
  }

  if (dicomStacks.length === 0) {
    return (
      <div>
        <Navbar />
        <LayoutContainer>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div>No Data Found...</div>
          </div>
        </LayoutContainer>
      </div>
    );
  }
  return (
    <div>
      <NavbarWithMeta metadata={dicomStacks[0].basic_metadata} />

      <LayoutContainer>
        <div
          style={{
            height: "45px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ToolButtons />
          {dicom_data.canSyncScans ? (
            <div style={{ padding: 4 }}>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    dispatch({
                      type: "DICOM_SYNC_SCANS",
                      syncScans: !dicom_data.syncScans,
                    })
                  }
                  color={dicom_data.syncScans ? "primary" : "default"}
                  size="small"
                  startIcon={<SyncIcon />}
                >
                  Synchronize Scans
                </Button>
              </ThemeProvider>
            </div>
          ) : null}
          <div style={{ padding: 4 }}>
            {dicomStacks.length > 0 &&
              dicom_data.dicom_data[dicomStacks[0].id]?.OP && (
                <MetaDataDialog
                  urls={{
                    op: dicom_data.dicom_data[dicomStacks[0].id].OP.url,
                    opt: dicom_data.dicom_data[dicomStacks[0].id].OPT.url,
                  }}
                />
              )}
            <HelpDialog />

            {/* <Button
              style={{ marginLeft: 10 }}
              onClick={() => setAddStackDialogOpen(true)}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
            >
              Add Stack
            </Button> */}
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              color="primary"
              size="small"
              startIcon={isSaving ? <CloudSyncIcon /> : <SaveIcon />}
              onClick={() => onSaveClick()}
            >
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>

        <Splitter
          style={{ height: "calc(100% - 40px)", padding: 0, margin: 0 }}
          onResizeFinished={handleResizeFinished}
          gutterClassName="custom-gutter-horizontal"
          initialSizes={gridSize}
        >
          <div style={{ height: "100%" }}>
            {dicomStacks.map((stack, index) => (
              <SingleDICOMViewer
                removeStack={removeStack}
                metadata={dicomStacks[0].basic_metadata}
                index={index}
                dicom_id={stack.id}
                key={`dcm-stack-${index}`}
                height={`${100 / dicomStacks.length}%`}
                urls={stack.files}
                annotations={
                  stack.annotations[query.get("series_no")] ?? stack.annotations
                }
                series_no={query.get("series_no")}
                stack={stack}
                masks={stack.masks}
                thickness_map={stack.thickness_map}
              />
            ))}
          </div>

          <div
            style={{ height: "100%", backgroundColor: "#FFF" }}
            id="form-side-bar"
          >
            <AnnotationsData
              request_info={{
                stack_id: query.get("stack_id"),
                series: query.get("series_no"),
                user_id: query.get("user_id"),
                role: query.get("role") || "grader",
              }}
            />
          </div>
        </Splitter>

        <AddStackDialog
          open={addStackDialogOpen}
          addStack={addStack}
          handleClose={() => setAddStackDialogOpen(false)}
        />
      </LayoutContainer>
    </div>
  );
}
